import axios from 'axios';
import JwtService from '@/core/services/jwt.service';

require('dotenv').config();

let pedagogicalDiaryClient;

export const PedagogicalDiaryApiService = {
    init() {
        let baseURL;
        if (process.env.DP_ENV === 'production') {
            baseURL = process.env.DP_API_URL;
        } else if (process.env.DP_ENV === 'development') {
            baseURL = process.env.DP_DEV_API_URL
        } else {
            baseURL = process.env.DP_LOCAL_API_URL;
        }

        pedagogicalDiaryClient = axios.create({
            baseURL,
            headers: {
                Authorization: `Bearer ${JwtService.getToken()}`,
                'Content-Type': 'application/json',
            },
        });
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        pedagogicalDiaryClient.defaults.headers[
            "Authorization"
        ] = `Bearer ${JwtService.getToken()}`;
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = '') {
        let path;
        if (slug == '') {
            path = `${resource}`;
        } else if (Array.isArray(slug)) {
            const plainSlug = slug.join('/');
            path = `${resource}/${plainSlug}`;
        } else {
            path = `${resource}/${slug}`;
        }
        return pedagogicalDiaryClient.get(path).catch((error) => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        return pedagogicalDiaryClient.post(`${resource}`, params);
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return pedagogicalDiaryClient.patch(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, slug, params) {
        return pedagogicalDiaryClient.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return pedagogicalDiaryClient.delete(resource).catch((error) => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },
    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    deleteFile(resource, slug, params) {
        return pedagogicalDiaryClient.delete(`${resource}/${slug}`, params);
    },
};
